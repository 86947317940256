<template>
  <div class="p-16px">
    <div class="text-22px text-hex-000000 leading-30px">{{ detail.title }}</div>
    <div class="flex items-center mt-16px text-15px text-hex-999999">
      <div class="text-hex-576b95" @click="jump"> 子春生集团</div>
      <div class="ml-10px">{{ detail.created_at }}</div>
    </div>
    <!-- 轮播图 -->
    <div class="mt-15px" v-if="detail.media_type == 1">
      <van-swipe :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(item,index) in imageList" :key="index">
          <van-image lazy-load width="100%" radius="8" height="184"
            :src="item" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 音频 -->
    <div class="mt-15px" v-if="detail.media_type == 2">
      <aplayer ref="map3PlayRef" :autoplay="false" :music="ma3Play"></aplayer>
    </div>
    <!-- 视频 -->
    <div class="mt-15px w-full" >
      <div id="dplayer1" class="rounded-8px w-full"></div>
    </div>
    <div v-html="detail.content" class="mt-25px"></div>
    <loading v-if="isFistLoading"></loading>
  </div>
</template>

<script>
import loading from '@/components/loading.vue'
import DPlayer from 'dplayer';
import aplayer from 'vue-aplayer';
export default {
  components: {
    loading,
    aplayer
  },
  data() {
    return {
      id: '',
      isFistLoading: true,
      detail: {},
    }
  },
  mounted() {
    const { id } = this.$route?.query ?? {}
    this.id = id
    this.getDate()
  },
  computed: {
    //音频
    ma3Play() {
      return {
        pic: this.detail?.cover ?? '',
        title: '',
        artist: ' ',
        src: this.detail?.media_content[0] ?? ''
      }
    },
    //图片
    imageList(){
      return this.detail?.media_content?.split(',')??[]
    }
  },
  methods: {
    jump(){
       window.location.href='https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg3NTY4MjkzNA==#wechat_redirect'
    },
    player() {
      if (this.detail.media_type == 3) {
        new DPlayer({
          container: document.getElementById('dplayer1'),
          video: {
            url: this.detail.media_content[0],
            pic:this.detail.cover
          },
        });
      }
    },
    async getDate() {
      try {
        const res = await this.$api.get(`/v1/articles/v2/${this.id}`)
        this.detail = res
        document.title = res.title
        this.player()
      } catch (err) { console.log(err) } finally {
        this.isFistLoading = false
      }

    }
  }
}
</script>

<style scoped>
::v-deep .dplayer-full-in-icon {
  display: none !important;
}

::v-deep .dplayer-video-wrap {
  border-radius: 8px !important;
  width: 100%;
  overflow: hidden;
}

::v-deep .dplayer-video-wrap .dplayer-video-current {
  object-fit: cover;
}

::v-deep .dplayer-controller-mask {
  border-radius: 8px !important;
}

::v-deep .aplayer-pic {
  width: 88px;
  height: 88px;
}

::v-deep .aplayer-pause {
  width: 22px !important;
  height: 22px !important;
}

::v-deep .aplayer-icon-pause {
  width: 18px !important;
  height: 18px !important;
}

::v-deep .aplayer-title {
  display: none !important;
}
</style>